import React, { useEffect } from 'react';
import get from 'lodash.get';
import { navigate } from 'gatsby';

const isProduction = process.env.GATSBY_ENVIRONMENT === 'production';

const isMobileBreadkpoint =
  typeof window !== 'undefined' ? window.innerWidth <= 600 : false;

export default function InspirationListWithUserId({ location }) {
  const pathName = get(location, 'pathname', '');

  useEffect(() => {
    if (isMobileBreadkpoint && typeof window !== 'undefined' && pathName) {
      const prefix = isProduction ? 'paidy' : 'paidy-stg';

      window.location = `${prefix}:/${pathName}`;
    } else {
      navigate('/shop');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
}
